import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="16"
      fill="none"
      viewBox="0 0 48 16"
      className='BurgerOpenIcon'
      {...props}
    >
      <path fill="#000" d="M0 0H48V4.005H0z"></path>
      <path fill="#000" d="M24 11.995H48V16H24z"></path>
    </svg>
  );
}

export default React.memo(Icon);
