import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="22"
      fill="none"
      viewBox="0 0 24 22"
    >
      <path
        fill="#000"
        d="M23.91 2.79L20.3 19.84c-.25 1.21-.98 1.5-2 .94l-5.5-4.07-2.66 2.57c-.3.3-.55.56-1.1.56-.72 0-.6-.27-.84-.95L6.3 12.7.85 11c-1.18-.35-1.19-1.16.26-1.75l21.26-8.2c.97-.43 1.9.24 1.53 1.73l.01.01z"
      ></path>
    </svg>
  );
}

export default React.memo(Icon);
