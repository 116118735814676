import React, { useState } from "react";
import { Formik, Form as FormikForm, Field } from "formik";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";

import { Button } from "../../Button";
import "./Form.scss";

const FormSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string()
    .email()
    .required("Required"),
  // phone: Yup.string().required("Required"),
  about: Yup.string()
});

export const Form = () => {
  const [captchaPassed, setCaptchaPassed] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const onCaptchaChange = async value => {
    if (value) {
      setTimeout(() => {
        setCaptchaPassed(true);
      }, 1000);
    } else {
      setCaptchaPassed(false);
    }
  };

  const getSubmitButtonText = ({
    formSubmitted,
    formSubmitting,
    submitError
  }) => {
    if (formSubmitted) {
      return "Отправлено";
    }
    if (formSubmitting) {
      return (
        <Loader
          type="Oval"
          color="black"
          height={24}
          width={24}
          timeout={3000}
        />
      );
    }
    if (submitError) {
      return "Произошла ошибка";
    }

    return "Отправить";
  };

  const postForm = async data => {
    setSubmitError(null);
    setFormSubmitting(true);
    try {
      const response = await fetch(`https://${window.location.host}/appeal`, {
        body: JSON.stringify(data),
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        setSubmitError(new Error("Статус не ок"));
      } else {
        setFormSubmitted(true);
      }
      setFormSubmitting(false);
    } catch (error) {
      setSubmitError(Error);
      setFormSubmitting(false);
    }
  };

  return (
    <div className="FormContainer">
      <div className="FormContainer__title" name="link_menu_4">
        начните проект с нами
      </div>
      <Formik
        initialValues={{
          name: "",
          email: "",
          // phone: "",
          about: ""
        }}
        validationSchema={FormSchema}
        onSubmit={values => {
          // same shape as initial values
          const data = {
            ...values,
          };
          postForm(data);
        }}
      >
        {({ errors, touched, isValid }) => (
          <FormikForm>
            {/* <Field name="interest" placeholder="Имя" /> */}
            {/* <Field name="interest">
              {({ field, form, meta }) => (
                <div>
                  <Select
                    theme={theme => ({
                      ...theme,

                      borderRadius: 0,

                      colors: {
                        ...theme.colors,
                        //   primary25: '#0d1bb7',
                        primary75: "black",
                        primary50: "black",
                        primary25: "grey",
                        primary: "black"
                      }
                    })}
                    className="FormSelect"
                    classNamePrefix="select"
                    options={options}
                    type="text"
                    {...field}
                    onChange={option => form.setFieldValue(field.name, option)}
                    placeholder="меня интересует..."
                  />
                  {errors.interest && touched.interest ? (
                    <div>{errors.interest}</div>
                  ) : null}
                </div>
              )}
            </Field> */}
            {/* {errors.interest && touched.interest ? (
              <div>{errors.interest}</div>
            ) : null} */}
            <Field className="name" placeholder="имя" name="name" type="name" />
            {/* {errors.name && touched.name ? <div>{errors.name}</div> : null} */}
            <div className="FormGroup">
              <Field
                placeholder="электронная почта"
                className="email"
                name="email"
                type="email"
              />
              {/* {errors.email && touched.email ? <div>{errors.email}</div> : null} */}
              {/* <Field
                placeholder="телефон"
                className="phone"
                name="phone"
                type="phone"
              /> */}
              {/* {errors.email && touched.email ? <div>{errors.email}</div> : null} */}
            </div>
            <Field name="about">
              {({ field, form, meta }) => (
                <div>
                  <textarea
                    className="about"
                    type="text"
                    {...field}
                    placeholder="расскажите нам немного о проекте, сроках и бюджете"
                  />
                  {meta.touched && meta.error && (
                    <div className="error">{meta.error}</div>
                  )}
                </div>
              )}
            </Field>
            {/* {errors.email && touched.email ? <div>{errors.email}</div> : null} */}
            <div className="FormContainer__actions">
              <ReCAPTCHA
                className="FormContainer__recaptcha"
                hl="ru"
                sitekey="6LcD79oUAAAAAE1HDDO1MQ5xuvTmOS3Av8AHewln"
                onChange={onCaptchaChange}
              />
              <Button
                disabled={
                  formSubmitted ||
                  !captchaPassed ||
                  !Object.keys(touched).length ||
                  !isValid
                }
                type="submit"
              >
                {getSubmitButtonText({
                  formSubmitted,
                  formSubmitting,
                  submitError
                })}
              </Button>
            </div>
            <div className="FormContainer__policy">
              Нажимая на кнопку Отправить, вы даете согласие на обработку своих{" "}
              <Link target="_blank" to="/policy">персональных данных.</Link>
            </div>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};
