import React from "react";
import { Link } from "react-scroll";
import { Button } from "../../Button";
import "./Main.scss";

export const Main = () => {
  return (
    <div className="Main">
      <div className="pic"/>
      <div className="Main_info">
        <span className="Main_info--bold">цифровизируем бизнес</span> партнеров
        для улучшения финансовых результатов и качества бренда
      </div>

      <Link
        to="link_menu_4"
        smooth={true}
        offset={-64}
        duration={500}
        delay={0}
      >
        <Button>узнать стоимость</Button>
      </Link>
    </div>
  );
};
