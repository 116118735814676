import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="72"
      fill="none"
      viewBox="0 0 96 72"
    >
      <path
        stroke="#0D1BB7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.903"
        d="M39.135 54.694l6.408-6.408a2.902 2.902 0 000-4.105l-4.086-4.076a7.255 7.255 0 01-9.507 3.869 7.256 7.256 0 010-13.376l-4.076-4.086a2.902 2.902 0 00-4.105 0l-6.408 6.408a7.258 7.258 0 10-7.258 7.258l-6.408 6.408a2.902 2.902 0 000 4.105l4.086 4.076a7.255 7.255 0 019.507-3.868 7.256 7.256 0 010 13.375l4.075 4.078a2.902 2.902 0 004.106 0l6.408-6.4a7.258 7.258 0 107.258-7.258z"
      ></path>
      <path
        stroke="#0D1BB7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.903"
        d="M32.185 30.452L26.92 25.06a2.902 2.902 0 010-4.105l4.086-4.076a7.26 7.26 0 0013.376 0 7.255 7.255 0 00-3.87-9.507l4.077-4.085a2.902 2.902 0 014.105 0l6.408 6.408a7.258 7.258 0 117.258 7.258l6.4 6.408a2.902 2.902 0 010 4.105l-4.077 4.076a7.258 7.258 0 10-9.507 9.507L51.1 45.135a2.902 2.902 0 01-4.105 0l-5.52-4.957"
      ></path>
      <path
        stroke="#0D1BB7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.903"
        d="M64.828 31.776l5.392-5.264a2.902 2.902 0 014.105 0l4.076 4.086a7.259 7.259 0 109.507 9.507l4.086 4.076a2.902 2.902 0 010 4.105l-6.409 6.408a7.258 7.258 0 11-7.257 7.258l-6.409 6.4a2.902 2.902 0 01-4.105 0l-4.076-4.078a7.259 7.259 0 10-9.507-9.507l-4.086-4.076a2.902 2.902 0 010-4.105l4.957-5.52"
      ></path>
    </svg>
  );
}

export default Icon;
