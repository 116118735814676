import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="49"
      fill="none"
      viewBox="0 0 48 49"
    >
      <path fill="#fff" d="M12 22.12H36V26.125H12z"></path>
      <path
        fill="#fff"
        d="M26.002 12.122H50.001999999999995V16.127H26.002z"
        transform="rotate(90 26.002 12.122)"
      ></path>
    </svg>
  );
}

export default React.memo(Icon);