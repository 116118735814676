import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="92"
      height="72"
      fill="none"
      viewBox="0 0 92 72"
    >
      <path
        stroke="#0D1BB7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.394"
        d="M65.736 42.448h11.313m-45.25-16.875a7.927 7.927 0 010-11.25v11.25zM83.665 54.65C65.642 75.725 23.661 70.573 6.692 59.323.127 54.969 5.059 37.43 9.708 24.86A60.37 60.37 0 0119.92 7.142a11.33 11.33 0 018.73-4.07h11.633c6.248 0 11.312 5.037 11.312 11.25 0 6.215-5.064 11.25-11.312 11.25h-8.485a21.628 21.628 0 00-5.656 16.876c6.716-6.945 15.494-10.313 25.453 0-.011-10.095 8.21-18.293 18.36-18.304 10.152-.011 18.395 8.164 18.406 18.259a18.223 18.223 0 01-4.713 12.244h.019v.003zM71.392 36.823v11.25-11.25z"
      ></path>
    </svg>
  );
}

export default Icon;
