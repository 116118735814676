/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from "react";
import { ResizeObserver } from "@juggle/resize-observer";

import { Person } from "../../Person";
import SEOIcon from "./SEOIcon.png";
import PartnerIcon from "./s_volkov.png";
import "./People.scss";

export const People = () => {
  const container = useRef(null);
  const background = useRef(null);
  let listenerAdded = false;
  let ro;
  useEffect(() => {
    if (!listenerAdded && container.current) {
      background.current.style.height = `${container.current.clientHeight}px`;
      ro = new ResizeObserver((entries, observer) => {
         const padding = parseInt(window.getComputedStyle(document.querySelector('.People'), null).getPropertyValue('padding-bottom'), 10)
        background.current.style.height = `${container.current.clientHeight - padding}px`;
      });
      ro.observe(container.current);

      listenerAdded = true;
    }
  }, [container, background]);

  useEffect(() => {
    return () => {
      ro.disconnect();
    };
  }, []);
  return (
    <div ref={container} className="People">
      <div ref={background} className="People__background"></div>
      <div className="People__grid">
        <Person
          icon={SEOIcon}
          name="Кайрат Матыбаев"
          position="Founder, CEO"
          description="«Развитие бизнеса — это процесс творческий и в нём у каждого свой почерк. Наш — в строгом следовании концепции матёрых профессионалов, для которых нет ничего сложного и невозможного. Всё четко и в срок»"
        />
        <Person
          icon={PartnerIcon}
          name="Сергей Волков"
          position="Co-Founder, CTO, Partner from Reagentum Software"
          description="«Правильная архитектура  ПО — не та, которая учитывает все требования, а та, которая учитывает, что требования могут меняться. И да, это возможно»"
        />
      </div>
    </div>
  );
};
