import React from "react";
import Logo from "./Logo";
import {BurgerMenu} from '../../containers/BurgerMenu'
import { Link  } from "react-router-dom";
import "./Header.scss";

export const Header = () => {
  return (
    <div className="Header">
      <Link to="/">
        <Logo />
      </Link>
      {window.location.pathname === "/" && 
      <BurgerMenu />
      }
    </div>
  );
};
