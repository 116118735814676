import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="244"
      height="43"
      fill="none"
      viewBox="0 0 244 43"
    >
      <path
        stroke="#000"
        strokeWidth="9.337"
        d="M3 36.892L53.52 6l47.095 30.892L148.566 6l38.532 30.892L241.043 6"
      ></path>
    </svg>
  );
}

export default React.memo(Icon);
