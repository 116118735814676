import React from "react";

import { Service } from "../../Service";
import StrategyIcon from "./StrategyIcon";
import DevIcon from "./DevIcon";
import TestingIcon from "./TestingIcon";
import ManageIcon from "./ManageIcon";
import LineBgIcon from "./LineBgIcon";
import "./QuickStart.scss";

export const QuickStart = () => {
  return (
    <div className="QuickStart" name="link_menu_1">
      <div className="QuickStart__title">методы</div>
      <div className="QuickStart__grid">
        <Service
          icon={StrategyIcon}
          title={"целостность"}
          description={
            "Предлагаем концепцию, делаем дизайн, проектируем и разрабатываем для mobile и web. Внедряем проект и сопровождаем технологически"
          }
        />
        <Service
          icon={DevIcon}
          title={"анализ"}
          description={
            "Аккуратно подбираем продукты при создании IT-инфраструктуры для достижения конкретных целей заказчика и партнера"
          }
        />
        <Service
          icon={TestingIcon}
          title={"экспертиза"}
          description={"Помощь зарубежных партнеров дает нам дополнительные компетенции в решении сложных задач"}
        />
        <Service
          icon={ManageIcon}
          title={"гибкость"}
          description={"Мы применяем гибкие методологии разработки программного обеспечения"}
        />
      </div>
      <div className="QuickStart__background"><LineBgIcon /></div>
    </div>
  );
};
