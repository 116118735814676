import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Layout } from "./components/Layout";
import { Header } from "./components/Header";
import { Landing, Policy, ReaFeedbacks } from "./pages";
import "normalize.css";
import "./App.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function App() {
  useEffect(() => {
    const preloader = document.getElementById("preloader");
    preloader.parentNode.removeChild(preloader);
  }, []);
  return (
    <div className="App">
      <Layout>
        <Router>
          <Switch>
            <Route exact path="/">
              <Header /><Landing />
            </Route>
            <Route exact path="/policy">
              <Header /><Policy />
            </Route>
            <Route exact path="/reafeedbacks">
              <Header /><ReaFeedbacks />
            </Route>
            <Route path="*">
              <Landing />
            </Route>
          </Switch>
        </Router>
      </Layout>
    </div>
  );
}

export default App;
