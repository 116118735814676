import React from "react";
import LineBgIcon from "../QuickStart/LineBgIcon";
import { Link } from "react-router-dom";

import "./Partnership.scss";

export const Partnership = () => {
    return (
      <div className="Partnership">
          <div className="Partnership__title">стратегическое партнёрство</div>
          <div className="PartnershipContainer">
            <div className="Partnership__item">
              <div className="PartnershipItem__description">ТОО «Sandyq Lab» образовано в 2020 году как сестринская компания российского ООО «Reagentum», которое получило много <Link className="PartnershipItem__link" target="_blank" to="/reafeedbacks">положительных отзывов</Link> от ведущих компаний в РФ.</div>
              <div className="PartnershipItem__description">Стратегическое партнерство Reagentum и Sandyq Lab — это общие компетенции и технологии, которые приносят выгоду клиентам уже более 5 лет.</div>
              <div className="PartnershipItem__icon"><LineBgIcon /></div>
            </div>
            <div className="Partnership__item">
              <div className="PartnershipItem__img" />
            </div>
          </div>
      </div>
    );
  };
  