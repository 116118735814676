import React from "react";

import "./Person.scss";

export const Person = ({ icon, name, position, description }) => {
  return (
    <div className="Person">
      <img className="Person__image" src={icon} alt=""/>
      <div className="Person__name">{name}</div>
      <div className="Person__position">{position}</div>
      <div className="Person__description">{description}</div>
    </div>
  );
};
