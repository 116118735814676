import React, { useState } from "react";
import { Link } from "react-scroll";

import BurgerOpenIcon from "./BurgerOpenIcon";
import CloseIcon from "./CloseIcon";
import Logo from "./Logo";

import "./BurgerMenu.scss";

export const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const className = !isOpen
    ? "BurgerMenu__container"
    : "BurgerMenu__container--open";
  const MenuItem = ({ children, to }) => (
    <Link
      onClick={() => setIsOpen(false)}
      className="BurgerMenuMenu__item"
      to={to}
      smooth={true}
      offset={-64}
      duration={500}
      delay={200}
    >
      {children}
    </Link>
  );

  return (
    <div className="BurgerMenu">
      <BurgerOpenIcon onClick={() => setIsOpen(!isOpen)} />
      <div className={className}>
        <div className="Header">
        <Logo />
        <button><CloseIcon onClick={() => setIsOpen(false)} /></button>
        </div>

          <div className="BurgerMenu__menu">
            <MenuItem to={"link_menu_3"}>про нас</MenuItem>
            <MenuItem to={"link_menu_2"}>портфолио</MenuItem>
            <MenuItem to={"link_menu_1"}>методы</MenuItem>
            <MenuItem to={"link_menu_4"}>контакты</MenuItem>
          </div>

      </div>
    </div>
  );
};
