import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="49"
      fill="none"
      viewBox="0 0 48 49"
    >
      <path fill="#fff" d="M12 22.122H36V26.127H12z"></path>
    </svg>
  );
}

export default React.memo(Icon);
