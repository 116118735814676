import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#000"
        d="M23.998 11.999c0-6.627-5.372-12-11.999-12C5.372 0 0 5.373 0 12c0 5.989 4.388 10.953 10.124 11.853v-8.385H7.078V12h3.046V9.355c0-3.007 1.792-4.668 4.532-4.668 1.313 0 2.686.234 2.686.234v2.953H15.83c-1.49 0-1.955.925-1.955 1.874v2.25h3.328l-.532 3.47h-2.796v8.384c5.736-.9 10.124-5.864 10.124-11.853z"
      ></path>
    </svg>
  );
}

export default React.memo(Icon);
