import React, { useState } from "react";
import Collapse from "@kunukn/react-collapse";
import MinusIcon from "./MinusIcon";
import PlusIcon from "./PlusIcon";
import "./Collapsable.scss";

export const Collapsable = ({
  buttonText,
  children,
  ExpandedIcon = MinusIcon,
  CollapsedIcon = PlusIcon
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="Collapsable">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="Collapsable__button CollapsableButton"
      >
        <span>{buttonText}</span>
        {isOpen ? <ExpandedIcon /> : <CollapsedIcon/>}
      </button>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </div>
  );
};
