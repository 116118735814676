import React from "react";

import "./Button.scss";

export const Button = ({ children, onClick, type, disabled }) => {
  return (
    <button onClick={onClick} type={type} disabled={disabled} className="Button">
      {children}
    </button>
  );
};
