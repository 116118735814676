import React from "react";
import { Button } from "../../components/Button";
import AlphaIcon from "./img/alpha.png";
import LanitIcon from "./img/lanit.png";
import { Link } from "react-router-dom";
import { Footer } from "../../components/Parts/Footer/Footer";

import "./ReaFeedbacks.scss";

export const ReaFeedbacks = () => {
  return (
    <div>
    <div className="ReaFeedbacks">
        <div className="ReaFeedbacks__title">Отзывы о работе “Reagentum” от ведущих компаний в РФ</div>
        <div className="ReaFeedbacksContainer">
            <div className="ReaFeedbacksItem">
                <div className="ReaFeedbacksItem__title">Альфа-Банк</div>
                <div className="ReaFeedbacksItem__description">
                    <img className="ReaFeedbacksItem__img" src={ AlphaIcon } alt="" />
                </div>
            </div>
            <div className="ReaFeedbacksItem">
                <div className="ReaFeedbacksItem__title">Ланит</div>
                <div className="ReaFeedbacksItem__description">
                    <img className="ReaFeedbacksItem__img" src={ LanitIcon } alt="" />
                </div>
            </div>
        </div>
        <div className="ReaFeedbacks__link">
            <Link to="/">
            <Button>на главную</Button>
            </Link>
        </div>
        
    </div>
    <Footer />
    </div>
  );
};
