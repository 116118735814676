/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from "react";
import { ResizeObserver } from "@juggle/resize-observer";
import { Link } from "react-scroll";
import "./CommonQuestions.scss";
import { Collapsable } from "../../../containers/Collapsable";

export const CommonQuestions = () => {
  const container = useRef(null);
  const background = useRef(null);
  let listenerAdded = false;
  let ro;

  useEffect(() => {
    if (!listenerAdded && container.current) {
      background.current.style.height = `${container.current.clientHeight}px`;
      ro = new ResizeObserver((entries, observer) => {
        background.current.style.height = `${container.current.clientHeight}px`;
      });
      ro.observe(container.current);

      listenerAdded = true;
    }
  }, [container, background]);

  useEffect(() => {
    return () => {
      ro.disconnect();
    };
  }, []);

  return (
    <div ref={container} className="CommonQuestions">
      <div ref={background} className="CommonQuestions__background" />
      <div className="CommonQuestions__title">общие вопросы</div>
      <div className="CommonQuestions__container">
        <div className="CommonQuestions__questions">
          <Collapsable buttonText={"Сколько стоит заказать разработку у вас?"}>
            <div className="CommonQuestions__answer">
              Стоимость зависит от уровня специалистов, назначенных в вашу команду и от сложности проекта. Пожалуйста,&nbsp;
              <Link
                to="link_menu_4"
                smooth={true}
                offset={-64}
                duration={500}
                delay={0}
                className="CommonQuestion__link"
              >свяжитесь</Link>
                &nbsp;с нами, и мы вышлем вам более подробную информацию, включая наши цены.
            </div>
          </Collapsable>
          <Collapsable buttonText={"Какие технологии вы используете?"}>
            <div className="CommonQuestions__answer">
                <div className="CommonQuestion__answerItem">
                <p>frontend:</p>
                <p>React, Redux, Mobx, TypeScript</p>
                <p>PWA — Native, React Native</p>
                <p>Система контроля: Git</p>
                <p>Система баг трекинга: Trello, Redmine</p>
                <p>CI: Jenkins, Gitlab ci (+Docker)</p>
                </div>
                <div className="CommonQuestion__answerItem">
                <p>backend:</p> 
                <p>ОС: win, unix</p>
                <p>БД: postgres, oracle, mongo, elasticsearch, rabbitmq, activemq</p>
                <p>tomcat, jboss, jetty, glassfish</p>
                <p>Java 8, SQL</p>
                <p>spring, spring-boot, mybatis, hibernate, jpa, jdbc</p>
                <p>junit, mockito</p>
                </div>
            </div>
          </Collapsable>
          <Collapsable
            buttonText={
              "Можем ли мы подписать NDA?"
            }
          >
            <div className="CommonQuestions__answer">Да, конечно. Мы хотим, чтобы ваша идея была в безопасности. Просто отправьте нам соглашение, и мы продолжим работать с ним. Если у вас его нет, то мы поможем его составить.</div>
          </Collapsable>
          <Collapsable
            buttonText={
              "Ваши разработчики работают удаленно?"
            }
          >
            <div className="CommonQuestions__answer">Да. У нас распределенная команда разработчиков с отлаженной коммуникацией. Мы всегда на связи и готовы работать эффективно, не зависимо от расстояния.</div>
          </Collapsable>
          <Collapsable
            buttonText={
              "Сколько времени вам нужно, чтобы приступить к разработке?"
            }
          >
            <div className="CommonQuestions__answer">Это зависит от количества текущих проектов. Иногда мы можем начать работать через несколько дней, иногда через несколько недель. Пожалуйста,&nbsp; 
            <Link
              to="link_menu_4"
              smooth={true}
              offset={-64}
              duration={500}
              delay={0}
              className="CommonQuestion__link"
            >свяжитесь
            </Link>&nbsp;с нами, чтобы получить больше информации.</div>
          </Collapsable>
        </div>
      </div>
    </div>
  );
};
