import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="91"
      fill="none"
      viewBox="0 0 90 91"
    >
      <g clip-path="url(#clip0)">
        <path
          stroke="#0D1BB7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3.394"
          d="M56.25 65.51v11.25c0 6.214-5.036 11.25-11.25 11.25H16.875c-6.214 0-11.25-5.036-11.25-11.25V14.885c0-6.213 5.036-11.25 11.25-11.25H45m35.359 4.017l4.016-4.017m-4.016 4.017l.536 14.032a12.659 12.659 0 01-5.82 10.384L49.822 47.323l-9.127-9.135L55.943 12.93a12.653 12.653 0 0110.38-5.816l14.036.537zM5.625 73.948H56.25M38.876 58.085c-4.005 4.002-12.018 2.25-12.018 2.25s-1.763-8.014 2.25-12a5.91 5.91 0 019.168.611 5.914 5.914 0 01.6 9.14zm8.194-30.461l-4.181-1.984a5.897 5.897 0 00-6.6.769l-5.914 5.914a1.46 1.46 0 00-.203 1.623c.267.63.792 1.114 1.437 1.336l9.067 2.92 6.394-10.578zm13.481 13.211l1.815 4.286a5.895 5.895 0 01-.75 6.6l-5.91 5.91c-.45.39-1.095.473-1.627.203a2.398 2.398 0 01-1.331-1.436l-2.925-9.072 10.728-6.49zm5.895-24.896a5.627 5.627 0 015.625 5.625 5.627 5.627 0 01-5.625 5.625 5.627 5.627 0 01-5.625-5.625 5.627 5.627 0 015.625-5.625z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0.823H90V90.823H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
