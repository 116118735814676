import React from "react";

import "./Service.scss";

export const Service = ({ icon: Icon, title, description }) => {
  return (
    <div className="Service">
      <Icon />
      <div className="Service__title">{title}</div>
      <div className="Service__description">{description}</div>
    </div>
  );
};
