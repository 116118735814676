import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
      className="CloseIcon"
      {...props}
    >
      <g fill="#000" clip-path="url(#clip0)">
        <path
          d="M5.615 39.554H53.615V43.554H5.615z"
          transform="rotate(-45 5.615 39.554)"
        ></path>
        <path
          d="M0 0H48V4H0z"
          transform="scale(1 -1) rotate(-45 -7.382 -10.999)"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h48v48H0V0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
