import React from "react";
import { Link } from "react-router-dom";

import FaceIcon from "./FaceIcon";
import TelegramIcon from "./TelegramIcon";
// import InstaIcon from "./InstaIcon";
import Logo from "../../Header/Logo";
import "./Footer.scss";

export const Footer = () => {
  return (
    <div className="Footer" name="link_menu_3">
      <div className="Footer__background"></div>
      <div className="Footer__main FooterMain">
        <div className="FooterMain__company">
          <Link to="/"><Logo />
          </Link>
        </div>
        <div className="FooterMain__info FooterMainInfo">
          <div className="FooterMainInfo__contacts">Контакты</div>
          <a href="tel:77005556571" className="FooterMainInfo__phone">
            +7 700 555 65 71
          </a>
          <a href="mailto:info@sandyqlab.kz" className="FooterMainInfo__email">
            info@sandyqlab.kz
          </a>
          {/* <div className="FooterMainInfo__address">
            14355 ул. Сатпаева 7, Алма-Ата, Казахстан
          </div> */}
          <div className="FooterMainInfo__social">
            <a href="https://www.facebook.com/SandyqLab/" rel="noopener noreferrer" target="_blank">
              <FaceIcon />
            </a>
            <a href="https://t.me/Puerto_MTB" rel="noopener noreferrer" target="_blank">
              <TelegramIcon />
            </a>
          </div>
        </div>
      </div>
      <div className="Footer__links FooterLinks">
        <span className="FooterLinks__company">© 2020 Sandyq Lab</span>
        <Link target="_blank" to="/policy">
          <span className="FooterLinks__pilicy">политика</span>
        </Link>
      </div>
    </div>
  );
};
