import React from "react";
import { Button } from "../../components/Button";
import { Link } from "react-router-dom";
import { Footer } from "../../components/Parts/Footer/Footer";

import "./Policy.scss";

export const Policy = () => {
  return (
    <div className="Policy">
      <div className="Policy__title">
        Политика в отношении обработки персональных данных ТОО «Sandyq Lab»
      </div>

      <ol>
        <li className="Policy__mark">
          Общие положения
          <ol>
            <li className="Policy__text">
              Настоящая политика (далее «политика») обработки персональных
              данных составлена и подготовлена в соответствии с требованиями с
              Закона Республики Казахстан от 21 мая 2013 года № 94-VЗРК «О
              персональных данных и их защите» и действует в отношении всех
              персональных данных, которые ТОО «Sandyq Lab» (далее Оператор)
              может получить от субъектов персональных данных.
            </li>
            <li className="Policy__text">
              Оператор ставит своей важнейшей целью и условием осуществления
              своей деятельности соблюдение прав и свобод человека и гражданина
              при обработке его персональных данных, в том числе защиты прав на
              неприкосновенность частной жизни, личную и семейную тайну.
            </li>
            <li className="Policy__text">
              Настоящая политика Оператора в отношении обработки персональных
              данных (далее – Политика) применяется ко всей информации, которую
              Оператор может получить о посетителях веб-сайта
              https://sandyqlab.kz.
            </li>
            <li className="Policy__text">
              Политика предназначена для изучения и неукоснительного исполнения
              руководителями и работниками всех структурных подразделений
              Товарищества, а так же подлежит доведению до сведения лиц,
              состоящих в договорных, гражданско-правовых, трудовых и иных
              отношениях с Товариществом, партнеров и других заинтересованных
              сторон.
            </li>
            <li className="Policy__text">
              Политика является общедоступным документом.
            </li>
          </ol>
        </li>
        <li className="Policy__mark">
          Основные понятия, используемые в Политике
          <ol>
            <li className="Policy__text">
              <b>Автоматизированная обработка персональных данных</b> – обработка
              персональных данных с помощью средств вычислительной техники;{" "}
            </li>
            <li className="Policy__text">
              <b>Блокирование персональных данных</b> – временное прекращение обработки
              персональных данных (за исключением случаев, если обработка
              необходима для уточнения персональных данных);{" "}
            </li>
            <li className="Policy__text">
              <b>Веб-сайт</b> – совокупность графических и информационных материалов, а
              также программ для ЭВМ и баз данных, обеспечивающих их доступность
              в сети интернет по сетевому адресу https://sandyqlab.kz;{" "}
            </li>
            <li className="Policy__text">
              <b>Информационная система персональных данных</b> — совокупность
              содержащихся в базах данных персональных данных, и обеспечивающих
              их обработку информационных технологий и технических средств;
            </li>
            <li className="Policy__text">
              <b>Обезличивание персональных данных</b> — действия, в результате которых
              невозможно определить без использования дополнительной информации
              принадлежность персональных данных конкретному Пользователю или
              иному субъекту персональных данных;{" "}
            </li>
            <li className="Policy__text">
              <b>Обработка персональных данных</b> – любое действие (операция) или
              совокупность действий (операций), совершаемых с использованием
              средств автоматизации или без использования таких средств с
              персональными данными, включая сбор, запись, систематизацию,
              накопление, хранение, уточнение (обновление, изменение),
              извлечение, использование, передачу (распространение,
              предоставление, доступ), обезличивание, блокирование, удаление,
              уничтожение персональных данных;
            </li>
            <li className="Policy__text">
              <b>Оператор</b> – государственный орган, муниципальный орган, юридическое
              или физическое лицо, самостоятельно или совместно с другими лицами
              организующие и (или) осуществляющие обработку персональных данных,
              а также определяющие цели обработки персональных данных, состав
              персональных данных, подлежащих обработке, действия (операции),
              совершаемые с персональными данными;
            </li>
            <li className="Policy__text">
              <b>Персональные данные</b> – любая информация, относящаяся прямо или
              косвенно к определенному или определяемому Пользователю веб-сайта
              https://sandyqlab.kz;
            </li>
            <li className="Policy__text">
              <b>Пользователь</b> – любой посетитель веб-сайта https://sandyqlab.kz;
            </li>
            <li className="Policy__text">
              <b>Предоставление персональных данных</b> – действия, направленные на
              раскрытие персональных данных определенному лицу или определенному
              кругу лиц; Распространение персональных данных – любые действия,
              направленные на раскрытие персональных данных неопределенному
              кругу лиц (передача персональных данных) или на ознакомление с
              персональными данными неограниченного круга лиц, в том числе
              обнародование персональных данных в средствах массовой информации,
              размещение в информационно-телекоммуникационных сетях или
              предоставление доступа к персональным данным каким-либо иным
              способом;
            </li>
            <li className="Policy__text">
              <b>Трансграничная передача персональных данных</b> – передача
              персональных данных на территорию иностранного государства органу
              власти иностранного государства, иностранному физическому или
              иностранному юридическому лицу;
            </li>
            <li className="Policy__text">
              <b>Уничтожение персональных данных</b> – любые действия, в результате
              которых персональные данные уничтожаются безвозвратно с
              невозможностью дальнейшего восстановления содержания персональных
              данных в информационной системе персональных данных и (или)
              результате которых уничтожаются материальные носители персональных
              данных.
            </li>
          </ol>
        </li>
        <li className="Policy__mark">
          Оператор может обрабатывать следующие персональные данные Пользователя
          <div className="Policy__text">
            Фамилия, имя, отчество; <br />
            Электронный адрес; <br />
            Номера телефонов; <br />
            Также на сайте происходит сбор и обработка обезличенных данных о
            посетителях (в т.ч. файлов «cookie») с помощью сервисов
            интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).
            Вышеперечисленные данные далее по тексту Политики объединены общим
            понятием Персональные данные. <br />
          </div>
        </li>
        <li className="Policy__mark">
          Цели обработки персональных данных
          <div className="Policy__text">
            Цель обработки персональных данных Пользователя — консультирование и
            уточнение деталей заказа. Также Оператор имеет право направлять
            Пользователю уведомления о новых продуктах и услугах, специальных
            предложениях и различных событиях. Пользователь всегда может
            отказаться от получения информационных сообщений, направив Оператору
            письмо на адрес электронной почты kayrat@sandyqlab.kz с пометкой
            «Отказ от уведомлениях о новых продуктах и услугах и специальных
            предложениях». Обезличенные данные Пользователей, собираемые с
            помощью сервисов интернет-статистики, служат для сбора информации о
            действиях Пользователей на сайте, улучшения качества сайта и его
            содержания.
          </div>
        </li>
        <li className="Policy__mark">
          Правовые основания обработки персональных данных
          <div className="Policy__text">
            Оператор обрабатывает персональные данные Пользователя только в
            случае их заполнения и/или отправки Пользователем самостоятельно
            через специальные формы, расположенные на сайте
            https://sandyqlab.kz. Заполняя соответствующие формы и/или отправляя
            свои персональные данные Оператору, Пользователь выражает свое
            согласие с данной Политикой. Оператор обрабатывает обезличенные
            данные о Пользователе в случае, если это разрешено в настройках
            браузера Пользователя (включено сохранение файлов «cookie» и
            использование технологии JavaScript).
          </div>
        </li>
        <li className="Policy__mark">
          Порядок сбора, хранения, передачи и других видов обработки
          персональных данных
          <ol>
            <li className="Policy__text">
              Безопасность персональных данных, которые обрабатываются
              Оператором, обеспечивается путем реализации правовых,
              организационных и технических мер, необходимых для выполнения в
              полном объеме требований действующего законодательства в области
              защиты персональных данных.
            </li>
            <li className="Policy__text">
              Оператор обеспечивает сохранность персональных данных и принимает
              все возможные меры, исключающие доступ к персональным данным
              неуполномоченных лиц.
            </li>
            <li className="Policy__text">
              Персональные данные Пользователя никогда, ни при каких условиях не
              будут переданы третьим лицам, за исключением случаев, связанных с
              исполнением действующего законодательства.
            </li>
            <li className="Policy__text">
              В случае выявления неточностей в персональных данных, Пользователь
              может актуализировать их самостоятельно, путем направления
              Оператору уведомление на адрес электронной почты Оператора
              kayrat@sandyqlab.kz с пометкой «Актуализация персональных данных».
            </li>
            <li className="Policy__text">
              Срок обработки персональных данных является неограниченным.
              Пользователь может в любой момент отозвать свое согласие на
              обработку персональных данных, направив Оператору уведомление
              посредством электронной почты на электронный адрес Оператора
              kayrat@sandyqlab.kz с пометкой «Отзыв согласия на обработку
              персональных данных».
            </li>
            <li className="Policy__text">
              Оператор не предоставляет и не раскрывает сведения, содержащие
              персональные данные работников, подрядчиков, исполнителей,
              контрагентов, иностранных граждан и лиц без гражданства третьим
              лицам без письменного согласия субъекта персональных данных, за
              исключением случаев, когда это необходимо в целях предупреждения
              угрозы жизни и здоровью, а также в случаях, установленных законами
              Республики Казахстан.
            </li>
            <li className="Policy__text">
              По мотивированному запросу исключительно для выполнения
              возложенных законодательством функций и полномочий персональные
              данные субъекта персональных данных без его согласия могут быть
              переданы:
              <ul>
                <li>в судебные органы в связи с осуществлением правосудия;</li>
                <li>в органы государственной безопасности;</li>
                <li>в органы прокуратуры;</li>
                <li>в органы полиции;</li>
                <li>в следственные органы;</li>
                <li>
                  в иные органы и организации в случаях, установленных
                  нормативными правовыми актами, обязательными для исполнения
                  Оператором.
                </li>
              </ul>
            </li>
            <li className="Policy__text">
              Работники Оператора, ведущие обработку персональных данных, не
              отвечают на вопросы, связанные с передачей персональных данных по
              телефону.
            </li>
          </ol>
        </li>
        <li className="Policy__mark">
          Трансграничная передача персональных данных
          <div className="Policy__text">
            Оператор до начала осуществления трансграничной передачи
            персональных данных обязан убедиться в том, что иностранным
            государством, на территорию которого предполагается осуществлять
            передачу персональных данных, обеспечивается надежная защита прав
            субъектов персональных данных. Трансграничная передача персональных
            данных на территории иностранных государств, не отвечающих
            вышеуказанным требованиям, может осуществляться только в случае
            наличия согласия в письменной форме субъекта персональных данных на
            трансграничную передачу его персональных данных и/или исполнения
            договора, стороной которого является субъект персональных данных.
          </div>
        </li>
        <li className="Policy__mark">
          Заключительные положения
          <ol>
            <li className="Policy__text">
              Пользователь может получить любые разъяснения по интересующим
              вопросам, касающимся обработки его персональных данных,
              обратившись к Оператору с помощью электронной почты
              kayrat@sandyqlab.kz. В данном документе будут отражены любые
              изменения политики обработки персональных данных Оператором.
              Политика действует бессрочно до замены ее новой версией.
            </li>
            <li className="Policy__text">
              Актуальная версия Политики в свободном доступе расположена в сети
              Интернет по адресу https://sandyqlab.kz/policy.
            </li>
            <li className="Policy__text">
              Настоящая Политика является внутренним документом Оператора,
              общедоступной и подлежит размещению на официальном сайте
              https://sandyqlab.kz/policy Оператора.
            </li>
            <li className="Policy__text">
              Настоящая Политика подлежит изменению, дополнению в случае
              появления новых законодательных актов и специальных нормативных
              актов по обработке и защите персональных данных.
            </li>
            <li className="Policy__text">
              Контроль исполнения требований настоящей Политики осуществляется
              лицом, ответственным за организацию обработки персональных данных
              Оператора.
            </li>
          </ol>
        </li>
      </ol>

      <div className="Policy__link">
        <Link to="/">
          <Button>на главную</Button>
        </Link>
      </div>
      <Footer />
    </div>
  );
};
