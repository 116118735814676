import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="86"
      height="91"
      fill="none"
      viewBox="0 0 86 91"
    >
      <path
        stroke="#0D1BB7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.375"
        d="M82.375 65.503V48.628m0 0H65.5m16.875 0L63.437 67.573a8.444 8.444 0 01-8.388 2.119l-21.424-5.73a8.442 8.442 0 00-8.145 2.186L3.625 88.018M43 20.518h-7.624a5.033 5.033 0 00-1.875 9.701l7.74 3.094a5.033 5.033 0 01-1.875 9.705H31.75m5.625-22.5v-2.813m0 28.125v-2.812m27.57-5.681c3.075-15.225-6.772-30.064-21.997-33.14-15.226-3.074-30.06 6.773-33.14 21.998A28.122 28.122 0 0020.5 54.265"
      ></path>
    </svg>
  );
}

export default Icon;
