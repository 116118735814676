import React from "react";

// import "./Landing.scss";
import {
  Main,
  AboutUs,
  QuickStart,
  People,
  Footer,
  Portfolio,
  CommonQuestions,
  Form,
  Partnership
} from "../../components/Parts";

export const Landing = () => {
  return (
    <React.Fragment>
      <Main />
      <AboutUs />
      <Partnership />
      <People />
      <Portfolio />
      <QuickStart />
      <CommonQuestions />
      <Form />
      <Footer />
    </React.Fragment>
  );
};
