import React from "react";
import { Button } from "../../Button/index";

// import Background from "./Background";

import "./AboutUs.scss";

export const AboutUs = () => {
  return (
    <div className="AboutUs" name="link_menu_3">
      <div className="AboutUs__background">{/* <Background /> */}</div>
      <div className="AboutUs__info">
        <div className="AboutUs__info__text">
          Мы создаем цифровые продукты и воплощаем в жизнь IT проекты. <br /> Для этого
          у нас есть экспертиза в информационных технологиях, человеческие и
          технические ресурсы.
        </div>
        <div className="AboutUs__info__text">
          Наша главная цель — понимать бизнес и поставлять ему современные
          технологии, приносящие прибыль.
        </div>
        <a href={`https://${window.location.host}/download/pres`} target="_blank" rel="noopener noreferrer" download='presentation.pdf'><Button >скачать презентацию</Button></a>
      </div>
    </div>
  );
};
