import React from "react";
import { Collapsable } from "../../../containers/Collapsable";

import "./Portfolio.scss";

export const Portfolio = () => {
  return (
    <div className="Portfolio" name="link_menu_2">
       <div className="Portfolio__title">портфолио</div> 
       <div className="Portfolio__description">(мы чтим NDA, поэтому сведения о клиентах скрыты)</div> 
      <Collapsable buttonText={'один из топ 10 банков РФ'}>
        <div className={`PortfolioContainer`}>
          <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">Проблематика</div>
            <div className="PortfolioItem__description">— Коммуникационные барьеры между сотрудниками POS блока</div>
            <div className="PortfolioItem__description">— Операционные барьеры между фронт-офисом и бэк-офисом</div>
            <div className="PortfolioItem__description">— Управление и контроль времени сотрудников</div>
          </div>
          <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">решение</div>
            <div className="PortfolioItem__description">Web-портал, устойчивый к высокой нагрузке (Java, JS, MySQL) :</div>
            <div className="PortfolioItem__description">— Обеспечивает online коммуникации POS Агент {'<->'} POS Агент и POS Агент {'<->'} back office <br /> — Контроль присутствия POS Агента на точке продаж <br /> — Динамическое управление назначением Агентов на точки продаж, замещения <br /> — Обучение продажам</div>
            <div className="PortfolioItem__description">Обеспечение безопасности: <br /> — Процесс первичной регистрации с применением технологий OTP <br /> — Сервис сброса и восстановления пароля</div>
          </div>
          <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">результат</div>
            <div className="PortfolioItem__description"> — POS бизнес получил в операционное распоряжение средство коммуникации, полностью закрывающее бизнес потребности<br /> — POS Агенты получили оперативный доступ к рабочей документации в режиме online <br /> — Повышена безопасность кредитного конвейера за счет процессов идентификации</div>
          </div>
        </div>
      </Collapsable>
      <Collapsable buttonText={'один из топ 100 банков РФ'}>
        <div className={`PortfolioContainer`}>
          <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">Проблематика</div>
            <div className="PortfolioItem__description">— Процесс рассмотрения заявок на банковские продукты занимает около недели</div>
            <div className="PortfolioItem__description">— Низкий бюджет на IT</div>
          </div>
          <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">решение</div>
            <div className="PortfolioItem__description">— Кредитный конвейер на BPM фреймворкес открытым исходным кодом (CamundaBPM, PostgreSQL)</div>
            <div className="PortfolioItem__description">— Разработка богатого интеграционного слоя для обогащения данными (в том числе с внешними источниками данных)</div>
            <div className="PortfolioItem__description">— Реализация pre-scoring механизма</div>
            <div className="PortfolioItem__description">— Разработка и внедрение конфигурабельногопродуктового каталога для описания продуктов Банка</div>
          </div>
          <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">результат</div>
            <div className="PortfolioItem__description">80% заявок обрабатываются за 15 минут</div>
          </div>
        </div>
      </Collapsable>
      <Collapsable buttonText={'одно из Министерств РФ'}>
        <div className={`PortfolioContainer`}>
          <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">Проблематика</div>
            <div className="PortfolioItem__description">Отсутствие централизованной IT системы (комплекса систем), обеспечивающего прозрачное моделирование предметной области, сбор и агрегацию первичных данных, а так же систем анализа и прогнозирования</div>
          </div>
          <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">решение</div>
            <div className="PortfolioItem__description">Full stack open source based IT infrastructure for:</div>
            <div className="PortfolioItem__description">— Сбор первичных данных — решение, базирующееся на платформе разработки Reagentum</div>
            <div className="PortfolioItem__description">— Средство управления структурой OLAP DWНчерез моделирование</div>
            <div className="PortfolioItem__description">— ETL слои перекачки данных из первичного хранилища в OLAP DWH</div>
          </div>
          <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">результат</div>
            <div className="PortfolioItem__description">Не внедрено</div>
          </div>
        </div>
      </Collapsable>
      <Collapsable buttonText={'один из топ 20 банков РФ'}>
        <div className={`PortfolioContainer`}>
          <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">Проблематика</div>
            <div className="PortfolioItem__description">Необходим перевод существующей системы расчета резервов на современные технологии</div>
          </div>
          <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">решение</div>
            <div className="PortfolioItem__description">— ETL слой — интеграционный слой для обмена данными с мастер системами</div>
            <div className="PortfolioItem__description">— BPM (state machine)</div>
            <div className="PortfolioItem__description">— Слой расчета резервов и инициация внутрибанковских транзакций</div>
          </div>
          <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">результат</div>
            <div className="PortfolioItem__description">— Система переведена на новые технологии</div>
            <div className="PortfolioItem__description">— Повышены скорость и качество работы Пользователей</div>
          </div>
        </div>
      </Collapsable>
      <Collapsable buttonText={'online кредитный брокер'}>
        <div className={`PortfolioContainer`}>
        <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">Предпосылки</div>
            <div className="PortfolioItem__description">E-commerce развивается, покупателям необходимо дать возможность покупать вкредит через on-line точки, как это было в POS;
              Банки технологически готовы рассматривать заявки в режиме реального времени и выдавать потребительские кредиты online;
              Кредитные продукты — двигатель продаж через online каналы.</div>
          </div>
          <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">Проблематика</div>
            <div className="PortfolioItem__description">В разных кредитных организациях процесс обработки кредитных заявок и набор документов для рассмотрения — разный.
                Online площадкам удобнее работать с брокером, а не с каждой кредитной организацией в отдельности
                Покупатель через online покупает быстро. Необходимо заполнять минимум информации и получать финансовый инструмент для покупки (любое положительное решение по кредиту)</div>
          </div>
          <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">решение</div>
            <div className="PortfolioItem__description">— Единая точка интеграции online POS со всеми доступными кредитными организациями;</div>
            <div className="PortfolioItem__description">— Нацеленность на получение любого положительного решения от кредитной организации для Клиента;</div>
            <div className="PortfolioItem__description">— Обеспечение планов продаж online POS за счет привлечения кредитных инструментов;</div>
            <div className="PortfolioItem__description">— Маркетплейс дополнительных услуг — страховые боксы, услуги партнеров и т. д.;</div>
            <div className="PortfolioItem__description">— Накопление big data. Аналитика и машинное обучение. Монетизация данных;</div>
            <div className="PortfolioItem__description">— Инструмент управления финансовой моделью брокера для получения максимальной прибыли — приоритезация финансовых организаций, настраиваемые модели пороговых процентных ставок агента от объема продаж и т. д.</div>
          </div>
          <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">результат</div>
            <div className="PortfolioItem__description">— Увеличение продаж МСБ (партнёров торговых точек) и банков-партнёров;</div>
            <div className="PortfolioItem__description">— Сбор big data по клиентам, кредитным заявкам и решениям;</div>
          </div>
        </div>
      </Collapsable>
      <Collapsable buttonText={'экспортный акселератор'}>
        <div className={`PortfolioContainer`}>
        <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">Заказчик</div>
            <div className="PortfolioItem__description">банк топ 10 в РФ</div>
          </div>
          <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">Предпосылки</div>
            <div className="PortfolioItem__description">— Государственное регулирование экспорта стратегических товаров и экспорта в целом.</div>
            <div className="PortfolioItem__description">— Производитель товаров не всегда может найти рынок сбыта, что приводит к снижению роста ВВП.</div>
            <div className="PortfolioItem__description">— Производитель товаров не всегда готов заниматься вопросами поиска контрагентов за рубежом, налаживанию деловых связей и инвестициям в маркетинг и международное торговое право</div>

          </div>
          <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">Проблематика</div>
            <div className="PortfolioItem__description">— У Заказчика есть своя клиентская база, но нет системы (Кабинет Экспортера), взаимодействующей с Государственным Экспортным центром. Из-за этого Банк теряет выгоду, т.к клиенты переходят на обслуживание РКО в другие банки. У которых есть Кабинет Экспортера</div>
            <div className="PortfolioItem__description">— Невозможность привлечение новых клиентов, ориентированных на экспортную деятельность.</div>
          </div>
          <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">решение</div>
            <div className="PortfolioItem__description">Встроенный в сайт в раздел «Для Бизнеса» Кабинет Экспортера (единое окно) с обеспечением следующих основных функций:</div>
            <div className="PortfolioItem__description">— Регистрация Клиента и <b>сопровождение до выхода на экспорт</b> <br />— <b>Поддержка Клиента</b> в вопросах вывода продукции на новые направления экспорта и вывода на экспорт новой продукции <br /> — <b>Сопровождение экспортных сделок</b> <br />  — <b>Бесшовная интеграция</b> с системами Государственного Экспортного Центра и государственными структурами обеспечения и контроля внешнеэкономической деятельности <br /> — <b>Единая система отчетности и мониторинга</b> Компании в части Экспорта и ВЭД <br /> — <b>Маркетплейс услуг</b> Банка, Государственных структур и иных Партнеров</div>
            
          </div>
          <div className="Portfolio__item PortfolioItem">
            <div className="PortfolioItem__title">результат</div>
            <div className="PortfolioItem__description">— Сохранение существующих Клиентов в экосистеме Банка за счет наличия критически важных функций деятельности Компаний-экспортеров</div>
            <div className="PortfolioItem__description"> — Сбор дополнительной информации о деятельности клиентов — экспортируемые товары, направления экспорта, объемы, контрактные обязательства, контрагенты  </div>
            <div className="PortfolioItem__description"> — Заведение полученной информации в системы оценки Клиента в других процессах банка</div>
            <div className="PortfolioItem__description"> — Клиенты Банка получили новый удобный инструмент работы с экспортом через единое окно Банка</div>
            <div className="PortfolioItem__description"> — Успешное представление системы на международном экономическом форуме</div>
          </div>
        </div>
      </Collapsable>
    </div>
  );
};
